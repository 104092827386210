import React, { useState, useRef } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../components/atoms/Button"
import Heading from "../components/atoms/Heading"
import SubHeader from "../components/atoms/SubHeader"
import Brands from "../components/organisms/Brands"
import Testimonials from "../components/organisms/Testimonials"
import AuthorisedVendor from "../components/organisms/AuthorisedVendor"
import ServiceQuoteSection from "../components/organisms/ServiceQuoteSection"
import DiagonalArrowIcon from "../images/icons/diagonal-arrow.svg"

export const query = graphql`
  {
    allWorksJson {
      nodes {
        title
        lead
        url
        tags
        hoverText
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, width: 720)
          }
        }
      }
    }
  }
`

const WorksPage = ({ data }) => {
  const works = data.allWorksJson
  const allWorks = []
  const allWorksTags = []
  const myRef = useRef(null)

  works.nodes.map(item => {
    return allWorks.push(item)
  })

  allWorks.map(item => {
    return allWorksTags.push(item.tags)
  })

  const uniqueTags = allWorksTags
    .flat()
    .sort()
    .filter((v, i, a) => a.indexOf(v) === i)

  const [categoryFilter, setCategoryFilter] = useState("")

  const handleCategoryFilter = e => {
    setCategoryFilter(e.currentTarget.innerHTML)
    executeScroll()
  }

  const handleClearCategoryFilter = () => {
    executeScroll()
    setCategoryFilter("")
  }

  const executeScroll = () =>
    myRef.current.scrollIntoView({
      behavior: "smooth",
    })

  return (
    <Layout headerBg="bg-beige">
      <Seo
        title="Digital products, mobile and web applications by Peak11"
        description="Explore innovative and customized digital products that were developed with and for inspirational individuals to address their unique and expansive needs"
      />
      <section className="bg-beige py-10 md:py-12 relative overflow-hidden">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="flex -mx-4">
            <div className="w-full md:w-3/4 lg:w-5/6 px-4">
              <div className="relative z-10 max-w-[980px]">
                <DiagonalArrowIcon
                  className="stroke-primary mr-2"
                  width="42px"
                  height="42px"
                  title="Arrow Icon"
                />
                <h1 className="text-h0/m lg:text-h0 mt-8">
                  Trails we’ve blazed and heights we’ve reached working together
                </h1>
                <div className="flex flex-col-reverse md:flex-col mt-8 md:mt-10">
                  <div className="md:text-3xl text-dark mt-10 md:mt-0 max-w-[740px] leading-[1.3]">
                    Explore innovative and customized software solutions that
                    were developed with and for inspirational individuals to
                    address their unique and expansive needs.
                  </div>
                  <div className="md:mt-14 md:mb-4">
                    <Button variant="contained" fullWidthMobile href="/contact">
                      Let's talk
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:block absolute top-0 left-[55%] h-full hero-bg-pattern w-[360px]"></div>
        <div className="hidden sm:block absolute top-0 left-[55%] h-full w-full hero-bg-pattern2 ml-[360px]"></div>
      </section>

      <section className="py-8 md:py-20 bg-white border-b-[16px] border-primary">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="mb-12">
            <SubHeader label="Works" />
            <Heading variant="h2">
              Tread along our most recently fashioned paths{" "}
            </Heading>
          </div>
          <div className="py-4 sticky top-0 z-30 bg-white">
            <ul className="flex items-center space-x-4 md:space-x-5 text-dark font-medium">
              <li
                className={`mb-0 ${
                  categoryFilter === "" ? "text-primary font-extrabold" : ""
                }`}
              >
                <button onClick={handleClearCategoryFilter}>All</button>
              </li>
              {uniqueTags.map(tag => {
                return (
                  <li
                    key={tag}
                    className={`mb-0 ${
                      categoryFilter === tag
                        ? "text-primary font-extrabold"
                        : ""
                    }`}
                  >
                    <button onClick={handleCategoryFilter}>{tag}</button>
                  </li>
                )
              })}
            </ul>
            <div className="bg-white z-[-1] transform -translate-x-1/2 absolute top-0 left-1/2 h-full w-[100vw]"></div>
          </div>
          <div className="md:pt-8 flex flex-wrap -mx-3" ref={myRef}>
            {works.nodes.map(work => {
              return (
                <div
                  className={`px-3 w-full md:w-1/2 lg:w-1/3 ${
                    work.tags.includes(categoryFilter) || categoryFilter === ""
                      ? "block"
                      : "hidden"
                  }`}
                  key={work.title}
                >
                  <div className="mt-8">
                    <div className="-mx-4 md:mx-0">
                      {work.url !== "" ? (
                        <a
                          href={work.url}
                          target="_blank"
                          rel="noreferrer"
                          className="block relative group"
                        >
                          <div className="aspect-w-16 aspect-h-11 shadow-[0_4px_12px_rgba(0,0,0,0.25)]">
                            <GatsbyImage
                              image={getImage(work.image)}
                              quality={100}
                              alt={`${work.title} application created by Peak11`}
                            />
                          </div>
                          {console.log(work.hoverText)}
                          <div className=" transition-opacity duration-300 ease-in-out absolute inset-0 bg-dark bg-opacity-80 text-beige text-base lg:text-[20px] font-extrabold flex justify-center items-center opacity-0 group-hover:opacity-100">
                            {work.hoverText}
                          </div>
                        </a>
                      ) : (
                        <div className="aspect-w-16 aspect-h-11 shadow-[0_4px_12px_rgba(0,0,0,0.25)]">
                          <GatsbyImage
                            image={getImage(work.image)}
                            quality={100}
                            alt={`${work.title} application created by Peak11 Custom Software House`}
                          />
                        </div>
                      )}
                    </div>
                    <div className="mt-6">
                      <Heading variant="h3" classes="font-extrabold mb-2">
                        {work.title}
                      </Heading>
                      <p className="mb-4 text-base">{work.lead}</p>
                      <ul className="flex flex-wrap text-xs">
                        {work.tags.map((item, index) => {
                          return (
                            <li
                              key={index}
                              className='after:content-["/"] after:inline-block after:mx-2 last:after:hidden'
                            >
                              <span
                                className={`${
                                  categoryFilter === item ? "text-primary" : ""
                                }`}
                              >
                                {item}
                              </span>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <section className="py-16 md:py-24 bg-dark">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="text-center">
            <h3 className="text-h0/m lg:text-hero/m text-beige">
              Curious about our works?
            </h3>
            <div className="text-beige text-base md:text-xl font-semibold mt-10 max-w-2xl xl:px-6 text-center mx-auto">
              Visit Peak11 Behance portfolio to discover details of the paths we
              treaded for challenging partners to address their unique needs.
            </div>
            <div className="mt-16">
              <Button
                variant="contained"
                small="small"
                fullWidthMobile
                target="_blank"
                href="https://www.behance.net/peak11"
              >
                Behance
              </Button>
            </div>
          </div>
        </div>
      </section>

      <Testimonials
        header="Your journey with Peak11"
        subHeader="Testimonials"
      />
      <Brands />
      <AuthorisedVendor
        header="We are listed on the top review sites"
        subHeader="Verified company"
      ></AuthorisedVendor>
      <ServiceQuoteSection
        text="Nelson Mandela understood
        that, “After climbing a great hill,
        one only finds that there are
        many more hills to climb”. At Peak11,
        we are prepared and determinedto meet your current and
        expansive needs."
      />
    </Layout>
  )
}

export default WorksPage
